/**
 * Check if an attribute value (received via props) is really truthy. This
 * allows setting attr="false" in the content (which would otherwise evaluate
 * as truthy, because the component actually receives the string "false").
 */
export function isTrue(value) {
    return value !== 'false' && !!value
}

/**
 * Check if a piece of content is visible given the secret status and whether it's secret
 */
export function isVisible(secret) {
    // eslint-disable-next-line
    return (
        !secret ||
        (typeof window !== 'undefined' &&
            (window.location.pathname === '/secret' || window.location.pathname === '/secret/'))
    )
}

export function getIndex(data, secret = false) {
    const index = data.allMarkdownRemark.edges.find(({ node }) => node.frontmatter.id === 'index')
    const ast = index.node.htmlAst
    if (!secret) return ast
    // Handle filtering of hidden content
    const children = ast.children.filter(
        ({ tagName, properties }) => tagName !== 'project' || properties.secret !== 'true'
    )
    return { ...ast, children }
}

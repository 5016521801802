import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import defaultSocialImage from '../images/social.jpg'

const SEO = () => (
    <StaticQuery
        query={query}
        render={data => {
            const lang = 'en'
            const siteMetadata = data.site.siteMetadata
            const pageTitle = `${siteMetadata.title} – ${siteMetadata.tagline}`
            const pageDesc = siteMetadata.description
            const socialImage = siteMetadata.siteUrl + defaultSocialImage
            const meta = [
                {
                    name: 'description',
                    content: pageDesc,
                },
                {
                    property: 'og:title',
                    content: pageTitle,
                },
                {
                    property: 'og:description',
                    content: pageDesc,
                },
                {
                    property: 'og:type',
                    content: `website`,
                },
                {
                    property: 'og:site_name',
                    content: siteMetadata.title,
                },
                {
                    property: 'og:image',
                    content: socialImage,
                },
                {
                    name: 'twitter:card',
                    content: 'summary_large_image',
                },
                {
                    name: 'twitter:image',
                    content: socialImage,
                },
                {
                    name: 'twitter:title',
                    content: pageTitle,
                },
                {
                    name: 'twitter:description',
                    content: pageDesc,
                },
            ]

            return <Helmet defer={false} htmlAttributes={{ lang }} title={pageTitle} meta={meta} />
        }}
    />
)

export default SEO

const query = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                tagline
                description
                siteUrl
            }
        }
    }
`

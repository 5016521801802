import React from 'react'
import classNames from 'classnames'

import { Block, ImageBlock } from './project'
import { isVisible } from '../util'
import classes from '../styles/embed.module.sass'

export const Embed = ({ tilt, width, secret, className, children, ...props }) => {
    const locked = !isVisible(secret)
    return locked ? (
        <ImageBlock width={350} height={200} locked={true} className={classes.placeholder} />
    ) : (
        <Block tilt={tilt} width={width}>
            <figure className={classNames(classes.root, className)} {...props}>
                {children}
            </figure>
        </Block>
    )
}

export const YouTube = ({ id, tilt, secret }) => (
    <Embed className={classes.responsive} tilt={tilt} secret={secret}>
        <iframe
            title={id}
            width="560"
            height="315"
            src={`https://www.youtube-nocookie.com/embed/${id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    </Embed>
)

export const SoundCloud = ({ id, color = '#222222', width = 750, tilt, secret }) => {
    const colorId = color && color[0] === '#' ? color.slice(1) : color
    const url = `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${id}&color=%23${colorId}&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`
    return (
        <Embed tilt={tilt} width={width} secret={secret}>
            <iframe
                title={id}
                width="100%"
                height={166}
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src={url}
            />
        </Embed>
    )
}

export const Video = ({ src, width = 838, height = 524, secret }) => {
    const embedClassNames = classNames(classes.video, classes.responsive)
    const ext = src.split('.').pop()
    const style = { paddingBottom: `${(height / width) * 100}%` }
    return (
        <Embed className={embedClassNames} style={style} secret={secret}>
            <video autoPlay loop muted playsInline width={width} height={height}>
                <source src={src} type={`video/${ext}`} />
            </video>
        </Embed>
    )
}

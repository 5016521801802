import React from 'react'
import classNames from 'classnames'
import { graphql, StaticQuery } from 'gatsby'

import SEO from './seo'
import Logo from './logo'
import { Link } from './typography'
import classes from '../styles/layout.module.sass'
import '../styles/base.sass'

export default ({ page = false, children }) => (
    <>
        <SEO />
        <StaticQuery
            query={query}
            render={({ site }) => {
                const tagline = site.siteMetadata.tagline_header
                return (
                    <header className={classes.header}>
                        <h1>
                            {page ? (
                                <Link to="/">
                                    <Logo />
                                </Link>
                            ) : (
                                <Logo />
                            )}
                        </h1>
                        <h2 className={classes.tagline}>
                            {tagline.split('|').map((line, i) => (
                                <>
                                    {i !== 0 && <span>|</span>}
                                    {line}
                                </>
                            ))}
                        </h2>
                    </header>
                )
            }}
        />
        <main className={classNames({ [classes.page]: page })}>{children}</main>
    </>
)

const query = graphql`
    query {
        site {
            siteMetadata {
                tagline_header
            }
        }
    }
`

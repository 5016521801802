import React, { useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Carousel, { Modal, ModalGateway } from 'react-images'

import { ImageBlock } from './project'
import { isVisible } from '../util'

function filterImageFiles(allImages, imageFiles) {
    const byPath = Object.assign(
        {},
        ...allImages.edges.map(({ node }) => ({ [node.relativePath]: node }))
    )
    return imageFiles.map(path => byPath[`images/${path}`]).filter(obj => obj)
}

function getPlaceholders(allImages, imageFiles) {
    return Object.assign(
        {},
        ...filterImageFiles(allImages, imageFiles).map(({ relativePath, childImageSharp }) => ({
            [relativePath]: childImageSharp.fluid.base64,
        }))
    )
}

export default ({ images = '', tilt, maxheight = 350, secret = false }) => {
    const visible = isVisible(secret)
    const maxHeight = parseInt(maxheight)
    const imageFiles = images.split(',').map(img => img.trim())
    const [isOpen, setIsOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)

    function toggleLightbox(event, index) {
        event.preventDefault()
        setIsOpen(!isOpen)
        setSelectedIndex(index)
    }

    return (
        <StaticQuery
            query={query}
            render={({ images, blurredImages }) => {
                const allImages = filterImageFiles(images, imageFiles)
                const allPlaceholders = getPlaceholders(blurredImages, imageFiles)
                const sources = allImages.map(({ publicURL }) => ({ src: publicURL }))
                return (
                    <>
                        {allImages.map((img, i) => {
                            const {
                                presentationWidth,
                                presentationHeight,
                            } = img.childImageSharp.fluid
                            const isScaled = presentationHeight > maxHeight
                            const height = isScaled ? maxHeight : null
                            const width = isScaled
                                ? (350 / presentationHeight) * presentationWidth
                                : 350
                            const placeholder = allPlaceholders[img.relativePath]
                            return (
                                <ImageBlock
                                    key={i}
                                    tilt={tilt === 'true'}
                                    width={width}
                                    height={height}
                                    locked={!visible}
                                >
                                    <a
                                        onClick={visible ? event => toggleLightbox(event, i) : null}
                                        href={visible ? img.publicURL : null}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {visible ? (
                                            <Img
                                                fluid={img.childImageSharp.fluid}
                                                fixed={img.childImageSharp.fixed}
                                                style={{ height: '100%' }}
                                                imgStyle={{ objectFit: 'contain' }}
                                            />
                                        ) : placeholder ? (
                                            <img
                                                width="100%"
                                                height="100%"
                                                style={{ padding: 0, margin: 0, display: 'block' }}
                                                src={placeholder}
                                            />
                                        ) : null}
                                    </a>
                                </ImageBlock>
                            )
                        })}
                        <ModalGateway>
                            {isOpen ? (
                                <Modal onClose={() => setIsOpen(!isOpen)}>
                                    <Carousel views={sources} currentIndex={selectedIndex} />
                                </Modal>
                            ) : null}
                        </ModalGateway>
                    </>
                )
            }}
        />
    )
}

const query = graphql`
    query {
        images: allFile(
            filter: { extension: { in: ["jpg", "jpeg", "png", "gif"] } }
            sort: { order: ASC, fields: [name] }
        ) {
            edges {
                node {
                    publicURL
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 400, quality: 100) {
                            ...GatsbyImageSharpFluid_noBase64
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
        blurredImages: allFile(
            filter: { extension: { in: ["jpg", "jpeg", "png", "gif"] } }
            sort: { order: ASC, fields: [name] }
        ) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 100, quality: 100) {
                            base64
                        }
                    }
                }
            }
        }
    }
`

import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import classNames from 'classnames'

import classes from '../styles/typography.module.sass'

export const H2 = ({ className, children }) => (
    <h2 className={classNames(classes.h, classes.h2, className)}>{children}</h2>
)

export const H3 = ({ className, children }) => (
    <h2 className={classNames(classes.h, classes.h3, className)}>{children}</h2>
)

export const Link = ({ children, to, href, onClick, hidden, className, ...other }) => {
    const dest = to || href
    const external = /((http(s?)):\/\/|mailto:)/gi.test(dest)
    const linkClassNames = classNames(classes.link, className, {
        [classes.linkHidden]: hidden,
    })

    if (!external) {
        if ((dest && /^#/.test(dest)) || onClick) {
            return (
                <a href={dest} onClick={onClick} className={linkClassNames}>
                    {children}
                </a>
            )
        }
        return (
            <GatsbyLink to={dest} className={linkClassNames} {...other}>
                {children}
            </GatsbyLink>
        )
    }
    return (
        <OutboundLink
            href={dest}
            className={linkClassNames}
            target="_blank"
            rel="noopener nofollow noreferrer"
            {...other}
        >
            {children}
        </OutboundLink>
    )
}

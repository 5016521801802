import React from 'react'
import rehypeReact from 'rehype-react'

import { Link } from './components/typography'
import { Project, Block } from './components/project'
import Gallery from './components/gallery'
import Footer from './components/footer'
import { YouTube, SoundCloud } from './components/embed'

export const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
        a: Link,
        project: Project,
        block: Block,
        youtube: YouTube,
        soundcloud: SoundCloud,
        gallery: Gallery,
        footer: Footer,
    },
}).Compiler

import React, { useState } from 'react'
import classNames from 'classnames'
import ScrollContainer from 'react-indiana-drag-scroll'
import slugify from 'slugify'

import { H2 } from './typography'
import { isTrue, isVisible } from '../util'
import classes from '../styles/project.module.sass'
import LockClosedIcon from '../images/icons/lock.svg'
import LockOpenIcon from '../images/icons/lock-open.svg'

export const Project = ({ title, color, accent, background, opacity, secret, children }) => {
    const id = slugify(title.replace('-', ''))
    const isUnlocked = isVisible(secret)
    const LockIcon = isUnlocked ? LockOpenIcon : LockClosedIcon
    const lockUrl = isUnlocked ? `/#${id}` : `/secret/#${id}`
    const [isScrolling, setIsScrolling] = useState(false)
    const style = {
        background,
        color,
        '--project-color': accent,
        '--project-opacity': opacity,
    }
    const scrollClassNames = classNames(classes.scroller, {
        [classes.scrollerActive]: isScrolling,
    })
    return (
        <section className={classes.root} style={style} id={id}>
            <div className={classes.wrapper}></div>

            <ScrollContainer
                horizontal={true}
                vertical={false}
                hideScrollbars={false}
                nativeMobileScroll={true}
                onStartScroll={() => setIsScrolling(true)}
                onEndScroll={() => setIsScrolling(false)}
                className={scrollClassNames}
            >
                {title && (
                    <H2 className={classes.title}>
                        {title.split('-').map((line, i) => (
                            <>
                                {i !== 0 && <br />}
                                {line}
                            </>
                        ))}
                        {isTrue(secret) && (
                            <a href={lockUrl} className={classes.lock}>
                                <LockIcon />
                            </a>
                        )}
                    </H2>
                )}
                {children}
            </ScrollContainer>
        </section>
    )
}

export const Block = ({ width = 350, height, tilt = false, locked, children, className }) => {
    const blockClassNames = classNames(classes.block, className, {
        [classes.tilt]: tilt,
    })
    const style = { width: `${width / 11}rem`, height: height ? `${height / 11}rem` : null }
    return (
        <div style={style} className={blockClassNames}>
            {locked && <LockClosedIcon className={classes.blockLock} />}
            {children}
        </div>
    )
}

export const ImageBlock = ({ width = 350, height, tilt, locked, children, className }) => (
    <Block width={width} height={height} className={classNames(classes.image, className)} tilt={tilt} locked={locked}>
        {children}
    </Block>
)

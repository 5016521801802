import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { H3, Link } from './typography'
import EmailIcon from '../images/icons/mail.svg'
import PhoneIcon from '../images/icons/phone.svg'
import LinkedInIcon from '../images/icons/linkedin.svg'
import XingIcon from '../images/icons/xing.svg'
import classes from '../styles/footer.module.sass'

const ICONS = { email: EmailIcon, phone: PhoneIcon, linkedin: LinkedInIcon, xing: XingIcon }

const Icon = ({ name, size = 16 }) => {
    const Component = ICONS[name]
    return <Component className={classes.icon} width={size} height={size} />
}

export default ({ title, children }) => {
    return (
        <StaticQuery
            query={query}
            render={({ profile, site }) => {
                const { email, phone, linkedin, xing } = site.siteMetadata
                return (
                    <footer className={classes.root}>
                        <section className={classes.content}>
                            {title && <H3>{title}</H3>}
                            <Img
                                className={classes.profile}
                                fixed={profile.childImageSharp.fixed}
                            />
                            {children}

                            <ul className={classes.links}>
                                <li>
                                    <Icon name="email" />{' '}
                                    <Link hidden to={`mailto:${email}`}>
                                        {email}
                                    </Link>
                                </li>
                                <li>
                                    <Icon name="phone" /> {phone}
                                </li>
                                <li>
                                    <Icon name="xing" />{' '}
                                    <Link hidden to={xing}>
                                        XING
                                    </Link>
                                </li>
                                <li>
                                    <Icon name="linkedin" />{' '}
                                    <Link hidden to={linkedin}>
                                        LinkedIn
                                    </Link>
                                </li>
                            </ul>
                        </section>

                        <section className={classes.fineprint}>
                            <Link hidden to="/impressum">
                                Impressum & Datenschutz
                            </Link>
                        </section>
                    </footer>
                )
            }}
        />
    )
}

const query = graphql`
    query {
        site {
            siteMetadata {
                email
                phone
                xing
                linkedin
            }
        }
        profile: file(relativePath: { eq: "images/profil.jpg" }) {
            childImageSharp {
                fixed(width: 200, height: 200, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`
